.error-6 {
  background-image: url('../../media/error/bg6.jpg');
}

.error.error-6 .error-title {
    font-size: 5rem !important; 
}
  
  @media (min-width: 768px) {
    .error.error-6 .error-title {
      font-size: 10rem !important;
     }
  }
  