.error-3 {
  background-image: url('../../media/error/bg3.jpg');
}

.error.error-3 .error-title {
  font-size: 8.7rem !important; }

@media (min-width: 768px) {
  .error.error-3 .error-title {
    font-size: 15.7rem !important; } }
