@font-face {
  font-family: 'Neutraface';
  src: url('../../media/agh/Neutraface/OpenType\ Fonts/NeutraText-Book.otf') format("opentype");
  font-weight: normal;
  font-style: normal;
}

.unsubscribe-3 {
  background-image: url('../../media/unsubscribe/bg10.png');
  font-family: "Lust RG";
}

.unsubscribe.unsubscribe-3 .unsubscribe-title {
  font-size: 6.0rem !important;
  padding-top: 270px !important;
}

.unsubscribe.unsubscribe-3 .font-size-h1 {
  font-family: "Neutraface";
  font-size: 1rem !important;
  color: black !important;
}

.unsubscribe.unsubscribe-3 .font-size-h1 a {

  color: black !important;
  text-decoration: underline;
  text-decoration-color: white;
}

.unsubscribe.unsubscribe-3 .font-size-h4 {
  font-family: "Neutraface";
  font-size: 1rem !important;
  color: black !important;
}

.unsubscribe.unsubscribe-3 .font-size-h5 a {
  font-family: "Neutraface";
  color: black !important;
  text-decoration: underline;
  text-decoration-color: white;

}

@media (min-width: 768px) {
  .unsubscribe-3 {
    background-image: url('../../media/unsubscribe/bg9.png');
    font-family: "Lust RG";
  }

  .unsubscribe.unsubscribe-3 .unsubscribe-title {
    font-size: 7.7rem !important;
  }

  .unsubscribe.unsubscribe-3 .font-size-h1 {
    font-family: "Neutraface";
    font-size: 1.8rem !important;
  }

  .unsubscribe.unsubscribe-3 .font-size-h4 {
    font-family: "Neutraface";
    font-size: 1.4rem !important;
    color: black !important;
  }


}